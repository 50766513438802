import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import HomeBanner from "../../component/pageSection/HomeBanner";
import {Card, List, Row, Col} from "antd";
import FooterSection from "../../component/pageSection/FooterSection";
import Meta from "antd/es/card/Meta";
import VehiclePledgeForm from "../../component/form/VehiclePledgeForm";

class Home extends Component<any, HomeStates> {
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);

        this.initState = {
            fetching: true
        }
        this.state = {...this.initState}
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader/>
                <HomeBanner/>
                <Row style={{paddingTop: 30, paddingBottom: 100}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col xl={{span: 10}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 20}}>
                                <VehiclePledgeForm/>
                            </Col>
                            <Col xl={{span: 14}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}} className={`centered`}>
                                <Row>
                                    <Col xl={{span: 8}} sm={{span: 12}} xs={{span: 12}} style={{padding: 7}}>
                                        <Card hoverable size={"small"} style={{width: "100%"}} cover={<img alt="img-1" src="/assets/img/mudah.png"/>}>
                                            <Meta title="Syarat Mudah" description="Pinjaman mudah, tanpa ribet"/>
                                        </Card>
                                    </Col>
                                    <Col xl={{span: 8}} sm={{span: 12}} xs={{span: 12}} style={{padding: 7}}>
                                        <Card hoverable size={"small"} style={{width: "100%"}} cover={<img alt="img-1" src="/assets/img/cepat.png"/>}>
                                            <Meta title="Pencairan Cepat" description="Dana siap pakai, tanpa lama"/>
                                        </Card>
                                    </Col>
                                    <Col xl={{span: 8}} sm={{span: 12}} xs={{span: 12}} style={{padding: 7}}>
                                        <Card hoverable size={"small"} style={{width: "100%"}} cover={<img alt="img-1" src="/assets/img/aman.png"/>}>
                                            <Meta title="Keamanan BPKB" description="BPKB aman, pinjaman terjamin"/>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{padding: '40px 0'}}>
                            <Col span={24}>
                                <Card title="Mengapa Memilih Pinjaman dengan Jaminan BPKB Kendaraan?" bordered={false} style={{width: '100%'}}>
                                    <p>
                                        Pinjaman dengan jaminan BPKB kendaraan adalah salah satu solusi cepat dan aman untuk mendapatkan dana tunai.
                                        Proses mudah, pencairan cepat, dan keamanan dokumen yang terjamin menjadi keunggulan utama kami.
                                    </p>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={[
                                            {
                                                title: 'Persyaratan Mudah',
                                                description: 'Cukup sediakan BPKB kendaraan dan dokumen pendukung lainnya.',
                                            }, {
                                                title: 'Proses Cepat',
                                                description: 'Proses pencairan dana yang cepat tanpa menunggu lama.',
                                            }, {
                                                title: 'Keamanan Terjamin',
                                                description: 'BPKB Anda aman di bawah pengelolaan kami hingga pinjaman lunas.',
                                            }, {
                                                title: 'Bunga Kompetitif',
                                                description: 'Bunga rendah dan cicilan ringan untuk kemudahan finansial Anda.',
                                            }
                                        ]}
                                        renderItem={item => (
                                            <List.Item key={item.title}>
                                                <List.Item.Meta title={item.title} description={item.description}/>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        <h2 style={{textAlign: "center"}}>Didukung Oleh</h2>

                        <Row justify="center">
                            <Col xl={{span: 4}} sm={{span: 8}} xs={{span: 8}} style={{padding: 7}}>
                                <Card hoverable size={"small"} style={{width: "100%", paddingTop: 20}} cover={<img alt="img-1" src="https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/leasing-image/adira-finance-logo.webp" style={{padding: 0, objectFit: "contain", height: "auto"}}/>}/>
                            </Col>
                            <Col xl={{span: 4}} sm={{span: 8}} xs={{span: 8}} style={{padding: 7}}>
                                <Card hoverable size={"small"} style={{width: "100%", paddingTop: 20}} cover={<img alt="img-1" src="https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/leasing-image/wom-finance-logo.webp" style={{padding: 0, objectFit: "contain", height: "auto"}}/>}/>
                            </Col>
                            <Col xl={{span: 4}} sm={{span: 8}} xs={{span: 8}} style={{padding: 7}}>
                                <Card hoverable size={"small"} style={{width: "100%", paddingTop: 20}} cover={<img alt="img-1" src="https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/leasing-image/mega-finance-logo.webp" style={{padding: 0, objectFit: "contain", height: "auto"}}/>}/>
                            </Col>
                            <Col xl={{span: 4}} sm={{span: 8}} xs={{span: 8}} style={{padding: 7}}>
                                <Card hoverable size={"small"} style={{width: "100%", paddingTop: 20}} cover={<img alt="img-1" src="https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/leasing-image/baf-finance-logo.webp" style={{padding: 0, objectFit: "contain", height: "auto"}}/>}/>
                            </Col>
                            <Col xl={{span: 4}} sm={{span: 8}} xs={{span: 8}} style={{padding: 7}}>
                                <Card hoverable size={"small"} style={{width: "100%", paddingTop: 20}} cover={<img alt="img-1" src="https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/leasing-image/mandiriutama-finance-logo.webp" style={{padding: 0, objectFit: "contain", height: "auto"}}/>}/>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <FooterSection/>

            </React.Fragment>
        );
    }
}

export default withRouter(Home);
