import React, {Component} from "react"
import {vehicleServices} from "../../services/autotrimitra/vehicleServices"
import {Select} from "antd"

class VehicleModelSelect extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
            brandCode: "",
            category: "",
            dataVehicleModel: [],
            selectVehicleModelValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleModelData = async (brandCode: any, category: any) => {
        if (brandCode) {
            await this.promisedSetState({
                loading: true,
            })

            try {
                vehicleServices.getVehicleModel({
                    brandUuid: brandCode,
                    category: category
                }).then(dataFetchModel => {
                    const dataForState: any = dataFetchModel.data.data.map((model: { model_uuid: string, market_name: string, class: string }) => ({
                        value: model.model_uuid,
                        name: model.market_name,
                        class: model.class,
                    }))
                    this.setState({
                        loading: false,
                        brandCode: brandCode,
                        dataVehicleModel: dataForState,
                        selectVehicleModelValue: null,
                    })
                })
            } catch (e) {
                this.setState({
                    loading: false,
                    brandCode: brandCode,
                    dataVehicleModel: [],
                    selectVehicleModelValue: null,
                })
            }
        } else {
            this.setState({
                brandCode: "",
                dataVehicleModel: [],
                selectVehicleModelValue: null,
            })
        }
    }

    onChange = async (value: any, option: any) => {
        this.setState({
            selectVehicleModelValue: value,
        })
        for await (let item of this.state.dataVehicleModel) {
            if (item.value === value) {
                option.class = item.class
            }
        }
        this.props.onVehicleModelChangeProps(option)
    }

    async componentDidMount() {
        await this.promisedSetState({
            brandCode: this.props.brandCode,
            category: this.props.category
        })
        this.fetchVehicleModelData(this.props.brandCode, this.props.category)
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.brandCode !== prevProps.brandCode) {
            this.fetchVehicleModelData(this.props.brandCode, this.props.category)
        }
        if (this.props.category !== prevProps.category) {
            this.fetchVehicleModelData(this.props.brandCode, this.props.category)
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Model Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.selectVehicleModelValue}
            >
                {this.state.dataVehicleModel.map((item: any, i: number) =>
                    <Select.Option key={item?.value} value={item?.value}>{item?.name}</Select.Option>
                )}
            </Select>
        )
    }
}

export default VehicleModelSelect