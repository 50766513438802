import React, {Component} from "react"
import {vehicleServices} from "../../services/autotrimitra/vehicleServices"
import {Select} from "antd"

class VehicleBrandSelect extends Component<any, any> {
    private initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
            category: "",
            dataVehicleBrand: [],
            selectVehicleBrandValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleBrandData = async (category: any) => {
        await this.promisedSetState({
            loading: true
        })
        try {
            vehicleServices.getVehicleBrand({category: category}).then(dataFetchBrand => {
                const dataForState: any = dataFetchBrand.data.data.map((brand: { brand_uuid: string, brand_name: string }) => ({
                    value: brand.brand_uuid,
                    name: brand.brand_name,
                }))
                this.setState({
                    loading: false,
                    category: category,
                    dataVehicleBrand: dataForState,
                    selectVehicleBrandValue: null,
                })
            })
        } catch (e) {
            this.setState({
                loading: false,
                category: category,
                dataVehicleBrand: [],
                selectVehicleBrandValue: null,
            })
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectVehicleBrandValue: value,
        })

        this.props.onVehicleBrandChangeProps(option)
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleBrandValue: value,
        })
    }

    async componentDidMount() {
        await this.promisedSetState({
            category: this.props.category
        })
        this.fetchVehicleBrandData(this.props.category)
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.category !== prevProps.category) {
            this.fetchVehicleBrandData(this.props.category)
        }

        if (this.props.value !== prevProps.value) {
            this.onValueChange(this.props.value)
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <Select
                showSearch
                loading={this.state.loading}
                placeholder="Pilih Merk Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.selectVehicleBrandValue}
            >
                {this.state.dataVehicleBrand.map((item: any, i: number) =>
                    <Select.Option key={item?.value} value={item?.value}>{item?.name}</Select.Option>
                )}
            </Select>
        )
    }
}

export default VehicleBrandSelect