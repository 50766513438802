import React, {Component} from "react";
import {areaServices} from "../../services/autotrimitra/areaServices";
import Select from "antd/lib/select";

class AreaCitySelect extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            dataCity: [],
            selectCityValue: null,
        }
        this.state = {...this.initState}
    }

    fetchCityData = (provinceCode: any) => {
        if (provinceCode) {
            try {
                areaServices.getCity({
                    provinceCode: provinceCode
                }).then(dataFetchCity => {
                    const dataForState: any = dataFetchCity.data.data.map((city: { code: string; administrative_type: string; name: string; }) => ({
                        value: city.code,
                        name: city.administrative_type+" "+city.name,
                    }));
                    this.setState({
                        dataCity: dataForState,
                        selectCityValue: this.props.cityName ?? null,
                    });
                });
            } catch (e) {
                this.setState({
                    dataCity: [],
                    selectCityValue: null,
                });
            }
        } else {
            this.setState({
                dataCity: [],
                selectCityValue: null,
            });
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectCityValue: value,
        });

        this.props.onAreaCityChangeProps(option);
    }

    async componentDidMount() {
        this.fetchCityData(this.props.provinceCode);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.provinceCode !== prevProps.provinceCode) {
            this.fetchCityData(this.props.provinceCode);
        }
    }

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Kota"
                optionFilterProp="children"
                onChange={this.onChange}
                style={{width: "100%"}}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.cityName ?? this.state.selectCityValue}
            >
                {
                    this.state.dataCity.map((item: any, i: number) =>
                        <Select.Option key={i} value={item?.value}>{item?.name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default AreaCitySelect;
