import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from './redux';
import Home from "./screen/home/Home";
import NotFound from "./screen/home/NotFound";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" caseSensitive={false} element={<Home/>}/>
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Router>
        </Provider>
    );
}

export default App