import React, {Component} from "react"
import VehicleBrandSelect from "../vehicle/VehicleBrandSelect"
import VehicleModelSelect from "../vehicle/VehicleModelSelect"
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import {Card, Select, Form, Input, Steps, Button, Modal, Slider} from "antd"
import {bqService} from "../../services/gcpBridgingBq/BqService";

const {Step} = Steps

class VehiclePledgeForm extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            currentStep: 0,
            fullName: "",
            phoneNumber: "",
            filterVehicleCategory: "car",
            vehicleBrand: null,
            vehicleModel: null,
            filterVehicleBrandUuid: null,
            filterVehicleModelUuid: null,
            modelUuid: null,
            modelName: null,
            modelClass: null,
            provinceCodeCurrent: null,
            provinceNameCurrent: null,
            cityCodeCurrent: null,
            cityNameCurrent: null,
            mileage: null,
            vehicleYear: new Date().getFullYear(),
            requiredAmountCar: 50,
            requiredAmountMotorcycle: 1,
            tenor: 3,
            loading: 0,
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "mileage" | "vehicleYear" | "filterVehicleCategory" | "fullName" | "phoneNumber">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "vehicleYear":
                currentState.vehicleYear = value
                break
            case "fullName":
                currentState.fullName = value
                break
            case "filterVehicleCategory":
                currentState.filterVehicleCategory = value
                currentState.filterVehicleBrandUuid = null
                currentState.filterVehicleModelUuid = null
                break
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '')
                break
            case "mileage":
                currentState.mileage = value.replace(/[^0-9.]/g, '');
                break;
        }
        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = ""
                }
            }
        }

        this.setState({
            ...currentState,
        })
    }

    onVehicleBrandChange = (data: any) => {
        this.setState({
            vehicleBrand: data?.children,
            filterVehicleBrandUuid: data?.key
        })
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            modelUuid: data.value,
            filterVehicleModelUuid: data?.value,
            modelName: data.children,
            modelClass: data.class,
        })
    }

    onAreaProvinceCurrentChange = (data: any) => {
        this.setState({
            provinceCodeCurrent: data.value,
            provinceNameCurrent: data.children,
            cityCodeCurrent: null,
            cityNameCurrent: null,
        });
    }

    onAreaCityCurrentChange = (data: any) => {
        this.setState({
            cityCodeCurrent: data.value,
            cityNameCurrent: data.children,
        });
    }

    onStepChange = (current: number) => {
        this.setState({currentStep: current})
    }

    sendData = async () => {
        const data = {
            source: "danabpkb",
            model_uid: this.state.modelUuid,
            vehicle_year: this.state.vehicleYear,
            vehicle_mileage: Number(this.state.mileage),
            required_amount: (this.state.filterVehicleCategory === "car") ? Number(this.state.requiredAmountCar * 1000000) : Number(this.state.requiredAmountMotorcycle * 1000000),
            tenor: this.state.tenor,
            province_code: this.state.provinceCodeCurrent,
            province_name: this.state.provinceNameCurrent,
            city_code: this.state.cityCodeCurrent,
            city_name: this.state.cityNameCurrent,
            name: this.state.fullName,
            phone_number: this.state.phoneNumber,
            email: ""
        }

        if (!data.city_code || !data.name || !data.phone_number) {
            return Modal.error({title: 'Proses Gagal', content: 'Mohon lengkapi data diri'});
        }
        if (!data.model_uid || !data.vehicle_year || !data.vehicle_mileage || !data.required_amount) {
            return Modal.error({title: 'Proses Gagal', content: 'Mohon lengkapi data kendaraan'});
        }
        if (data.phone_number.length < 8) {
            return Modal.error({title: 'Proses Gagal', content: 'nomor telpon tidak valid'});
        }

        this.setState({loading: true})

        try {
            await bqService.setToken(this.state.token);
            await bqService.sendLog(data);
            // await this.promisedSetState({loading: false});
            Modal.success({
                title: 'Proses Sukses',
                content: 'Terimakasih telah melakukan apply data. kami akan menghubungi anda dalam beberapa waktu kedepan'
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (e) {
            await this.promisedSetState({
                loading: false
            });
            Modal.error({title: 'Error', content: " " + e});
        }
    }

    onChangeSliderCar = (value: any) => {
        this.setState({requiredAmountCar: value});
    }
    onChangeSliderMotorcycle = (value: any) => {
        this.setState({requiredAmountMotorcycle: value});
    }
    onChangeTenor = (value: any) => {
        this.setState({tenor: value});
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        const marksCar = {50: '50jt', 100: '100jt', 250: '250jt', 500: '500jt'}
        const marksMotorcycle = {1: '1jt', 10: '10jt', 25: '25jt', 50: '50jt'}
        const marksTenor = {3: '3bulan', 12: '12bulan', 36: '36bulan', 60: '60bulan'}

        const years = [];
        for (let i = this.state.vehicleYear; i >= this.state.vehicleYear - 10; i--) {
            years.push(i.toString());
        }
        return (
            <>
                <Card title="Tertarik Dengan Pinjaman" hoverable={true} style={{width: "100%", marginTop: "-120px"}}>
                    <div style={{marginBottom: 20}}>
                        <Steps current={this.state.currentStep} onChange={this.onStepChange}>
                            <Step title="Data Diri"/>
                            <Step title="Data Kendaraan"/>
                        </Steps>
                    </div>

                    <div style={this.state.currentStep === 0 ? {} : {display: 'none'}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                            <Input value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor Telpon *</i>
                            <Input value={this.state.phoneNumber} maxLength={17} placeholder="Format: 08xxxxxxxx" onChange={event => this.onFieldChange("phoneNumber", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi Saat Ini *</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceCurrentChange} country={"idn"} provinceCode={this.state.provinceCodeCurrent} provinceName={this.state.provinceNameCurrent}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Saat Ini *</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityCurrentChange} provinceCode={this.state.provinceCodeCurrent} cityName={this.state.cityNameCurrent}/>
                        </Form.Item>
                        <Button type={'primary'} onClick={() => this.setState({currentStep: 1})}>Next</Button>
                    </div>
                    <div style={this.state.currentStep === 1 ? {} : {display: 'none'}}>
                        <Form.Item>
                            <i className={`small-text-grey`}>Kategori Kendaraan*</i>
                            <Select showSearch placeholder="Pilih Kategori Kendaraan" optionFilterProp="children" onChange={value => this.onFieldChange("filterVehicleCategory", value)} value={this.state.filterVehicleCategory}>
                                <Select.Option key={`car`} value={`car`}>{`Mobil`}</Select.Option>
                                <Select.Option key={`motorcycle`} value={`motorcycle`}>{`Motor`}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Merk Kendaraan*</i>
                            <VehicleBrandSelect onVehicleBrandChangeProps={this.onVehicleBrandChange} category={this.state.filterVehicleCategory}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Model Kendaraan*</i>
                            <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} brandCode={this.state.filterVehicleBrandUuid} category={this.state.filterVehicleCategory}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Tahun Kendaraan *</i>
                            <Select
                                onChange={value => this.onFieldChange("vehicleYear", value)}
                                value={this.state.vehicleYear}
                                style={{width: '100%'}}
                                placeholder={`Tahun Kendaraan`}
                            >
                                {years.map((item: any, i: number) => {
                                    return (
                                        <Select.Option key={i} value={item}>{item}</Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kilometer Kendaraan</i>
                            <Input maxLength={16} value={this.state.mileage} placeholder="Kilometer kendaraan" onChange={event => this.onFieldChange("mileage", event.target.value)}/>
                        </Form.Item>
                        <Form.Item style={{marginTop: 10}}>
                            <i className={`text-small-grey-nopadding`}>Dana yang dibutuhkan *</i>
                            <div style={this.state.filterVehicleCategory === "car" ? {} : {display: 'none'}}>
                                <Slider
                                    min={50}
                                    max={500}
                                    step={10}
                                    marks={marksCar}
                                    value={this.state.requiredAmountCar}
                                    onChange={this.onChangeSliderCar}
                                />
                            </div>
                            <div style={this.state.filterVehicleCategory === "motorcycle" ? {} : {display: 'none'}}>
                                <Slider
                                    min={1}
                                    max={50}
                                    step={5}
                                    marks={marksMotorcycle}
                                    value={this.state.requiredAmountMotorCycle}
                                    onChange={this.onChangeSliderMotorcycle}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item style={{marginTop: 10}}>
                            <i className={`text-small-grey-nopadding`}>Tenor*</i>
                            <Slider
                                min={3}
                                max={60}
                                step={3}
                                marks={marksTenor}
                                value={this.state.tenor}
                                onChange={this.onChangeTenor}
                            />
                        </Form.Item>
                        <Button onClick={() => this.sendData()} loading={this.state.loading} type={'primary'}>Kirim Data</Button>
                    </div>
                </Card>
            </>
        )
    }
}

export default VehiclePledgeForm