import React, {Component} from "react";
import './PageHeader.css'
import Cookies from "universal-cookie";
// import {Button} from "antd";
import {Link} from "react-router-dom";

class PageHeader extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
            headerScrollDown: true,
            showMobileMenu: false,
        }
        this.state = {...this.initState}
    }

    onClickMobileLeftMenu = async () => {
        this.setState({
            showMobileMenu: (!this.state.showMobileMenu),
        });
    }

    render() {
        // const c = this.cookies.get('_tc-t');

        window.addEventListener("scroll", () => {
            // if (window.scrollY >= 85) {
            //     this.setState({
            //         headerScrollDown: true
            //     });
            // } else {
            //     this.setState({
            //         headerScrollDown: false
            //     });
            // }
        });

        return (
            <React.Fragment>
                <header className={`header headerMenu ${(this.state.headerScrollDown) ? "on-scroll" : ""}`} id="header">
                    <nav className="navbar container">
                        <Link to={`/`}>
                            {/*<div style={{backgroundColor: "rgb(255 255 255 / 58%)", borderRadius: 10, padding: 5}}>*/}
                            <img style={{height: 50}} src={`/assets/img/logo-dana.png`} alt={`logo`}/>
                            {/*</div>*/}
                        </Link>
                        <div className={`burger ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="burger" onClick={this.onClickMobileLeftMenu}>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                        </div>
                        <div className={`menu ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="menu">
                            <ul className="menu-inner">
                                <li className="menu-item" key={1}><a href="/" className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>Home</b></a></li>
                                <li className="menu-item" key={1}><a href="/" className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>Layanan</b></a></li>
                                <li className="menu-item" key={2}><a href="/" className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>About Us</b></a></li>
                                <li className="menu-item" key={4}><a href="/" className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>FAQ</b></a></li>
                            </ul>
                        </div>
                        {/*
                        <Link style={!c ? {} : {display: 'none'}} to={`/login`}>
                            <Button style={{float: "right"}} type="primary">Login/Register</Button>
                        </Link>
                        <Link style={c ? {} : {display: 'none'}} to={`/my-profile`}>
                            <Button style={{float: "right"}} type="primary">Profile</Button>
                        </Link>
                        */}
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}

export default PageHeader;