import axios from "axios";
import {baseUrlGcpBridging} from "../../config/apiConfig/apiConfig";

class BqService {
    private axios = axios.create({
        baseURL: baseUrlGcpBridging,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async sendLog(params: any) {
        try {
            return await this.axios.post<any>('/log/trimobi/vehicle-pledge', params, {
                headers: {"X-Api-Key": "-"}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const bqService = new BqService();